/* hero.css */

.hero {
  /* Add some padding to the hero section */
  padding: 2rem;
}

.carousel {
  /* Make the carousel full-width */
  width: 100%;
  margin: 0 auto;
}

.carousel-inner {
  /* Add some padding to the carousel inner container */
  padding: 1rem;
}

.carousel-item {
  /* Make the carousel items full-width */
  width: 100%;
}

.carousel-item img {
  /* Set the image size to 8.5x11 inches (landscape) */
  width: 850px; /* 8.5 inches x 96 dpi */
  height: 800px; /* 11 inches x 96 dpi */
  object-fit: contain; /* Scale the image to fit the container */
  border-radius: 10px; /* add some rounded corners */
}

.carousel-caption {
  /* Style the carousel captions */
  background-color: rgba(255, 255, 255, 0);
  padding: 1rem;
  border-radius: 10px;
  color: #fff;
  display: block; /* make the caption visible on all devices */
}

.carousel-caption button {
  /* Style the "Learn More & Verify" buttons */
  background-color: #4CAF50;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block; /* make the button inline-block to avoid wrapping */
}

.carousel-caption button:hover {
  background-color: #23527c;
}

.carousel-control-prev,.carousel-control-next {
  /* Style the carousel navigation buttons */
  background-color: #337ab7;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.carousel-control-prev:hover,.carousel-control-next:hover {
  background-color: #23527c;
}