/* Global styles */

.project-detail {
  max-width: 800px;
  margin: 20px auto;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.project-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.project-image {
  max-width: 100%;
  max-height: 400px; /* adjust this value to your liking */
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 10px;
}

.project-description {
  font-size: 16px;
  margin-bottom: 10px;
}

.project-solution-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.project-solution {
  font-size: 16px;
  margin-bottom: 10px;
}

.project-technologies-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.project-technologies-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.project-technology-item {
  font-size: 16px;
  margin-bottom: 5px;
}

.project-duration-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.project-duration {
  font-size: 16px;
  margin-bottom: 10px;
}

.project-role-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.project-role {
  font-size: 16px;
  margin-bottom: 10px;
}

.project-back-button {
  background-color: #4CAF50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.project-back-button:hover {
  background-color: #3e8e41;
}