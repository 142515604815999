.experience {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.experience ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.experience li {
  margin: 10px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.experience h2 {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: bold;
}

.experience h3 {
  margin-bottom: 5px;
}

.experience p {
  font-size: 14px;
  margin-bottom: 10px;
}

.experience.duration {
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
}