.about {
  max-width: 800px;
  margin: 40px auto;
  padding: 30px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.about h2 {
  margin-top: 0;
  font-weight: bold;
  color: #333;
  font-size: 28px;
  margin-bottom: 15px;
}

.about p {
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 1.5;
  color: #666;
  text-align: justify;
}

.about p:last-child {
  margin-bottom: 0;
}

.strengths {
  font-weight: bold;
  color: #337ab7;
  margin-top: 25px;
}

.weaknesses {
  font-weight: bold;
  color: #d9534f;
  margin-top: 25px;
}

.strengths::before {
  content: "優點：";
  font-weight: bold;
  color: #337ab7;
  margin-right: 15px;
}

.weaknesses::before {
  content: "缺點：";
  font-weight: bold;
  color: #d9534f;
  margin-right: 15px;
}

.motto {
  font-size: 36px; /* increased font size for more impact */
  font-weight: bold;
  color: #337ab7;
  text-align: center;
  margin: 30px 0; /* increased margin for more breathing room */
  padding: 20px; /* increased padding for more prominence */
  border-radius: 20px; /* increased border radius for a softer look */
  background-color: #f7f7f7;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); /* increased shadow for more depth */
}