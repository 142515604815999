.header {
  background-color: #F7F7F7; /* a light gray color */
  color: #333333; /* dark gray color */
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* add a subtle shadow */
  margin-bottom: 30px; /* add 30px margin bottom */
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.profile-picture {
  width: 120px; /* adjust the size to fit your image */
  height: 120px;
  border-radius: 50%; /* make the image circular */
  overflow: hidden;
  margin: 40px auto; /* increase margin top and bottom to 40px */
  border: 2px solid #FFFFFF; /* add a white border */
}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%; /* make the image circular */
}

.header-content h1 {
  font-size: 36px;
  margin-bottom: 10px;
  color: #212121; /* dark gray color */
  font-weight: bold; /* make the font bold */
}

.header-content p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #666666; /* medium gray color */
}

.header-content button {
  background-color: #4CAF50; /* a bright green color */
  color: #FFFFFF; /* white color */
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px; /* add a subtle border radius */
}

.header-content button:hover {
  background-color: #3e8e41; /* a darker green color */
}

.certification {
  margin-top: 20px;
}

.certification h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #212121; /* dark gray color */
}

.certification p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #666666; /* medium gray color */
}

.navbar-brand {
  padding: 10px 20px; /* add 10px top/bottom padding and 20px left/right padding */
}

.nav-link {
  padding: 10px 20px; /* add 10px top/bottom padding and 20px left/right padding */
}

.nav-link.active {
  background-color: #E5E5EA; /* a light gray color */
  color: #333333; /* dark gray color */
}

.nav-link:hover {
  background-color: #D7D7D7; /* a light gray color */
  color: #333333;
}