.footer {
  background-color: #f7f7f7; /* light gray background */
  padding: 20px; /* add some padding */
  text-align: center; /* center the content */
  color: #333; /* dark gray text color */
}

.footer ul {
  list-style: none; /* remove bullet points */
  margin: 0; /* remove margin */
  padding: 0; /* remove padding */
  display: flex; /* make it a flex container */
  justify-content: center; /* center the links */
}

.footer li {
  margin-right: 20px; /* add some space between links */
}

.footer a {
  color: #337ab7; /* blue link color */
  text-decoration: none; /* remove underline */
  transition: color 0.2s ease; /* add a transition effect */
}

.footer a:hover {
  color: #23527c; /* darker blue on hover */
}